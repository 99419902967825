<template>
  <div class="examinfodetail" v-html="examinfo.contentDetail"></div>
</template>

<script>
export default {
  data() {
    return {
      examinfo: {},
    };
  },
  methods: {
    renderdetail() {
      const id = this.$route.query.id
      const examinfolist = JSON.parse(sessionStorage.getItem("examinfolist"));
      // const examinfolist = this.$route.params.allexaminfolist
      for (const item of examinfolist) {
        if (item.id==id) {
          this.examinfo=item
          return
        }
      }
    },
  },
  mounted() {
    this.renderdetail()
    // console.log(JSON.parse(sessionStorage.getItem("examinfolist")));
  },
};
</script>

<style lang="less" scoped>
.examinfodetail{
  padding: 5px;
}

/deep/ span{
  font-size: 16px !important;
  background: transparent !important;
}
</style>
